type UrlQueryCodec<T> = {
  name: string;
  toValue: (text: string) => T;
  fromValue: (value: T) => string;
};

const activePage: UrlQueryCodec<"buy" | "sell" | "info" | "comments"> = {
  name: "t",
  toValue: (text) => {
    switch (text) {
      case "b":
        return "buy";
      case "s":
        return "sell";
      case "c":
        return "comments";
      default:
        return "info";
    }
  },
  fromValue: (value) => {
    switch (value) {
      case "buy":
        return "b";
      case "sell":
        return "s";
      case "comments":
        return "c";
      default:
        return "";
    }
  },
};

export const urlQueryCodecs = {
  activePage,
};

export const urlQueryCodecs$PageGalleryArtwork = urlQueryCodecs;
