import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import * as React from "react";

import styles from "./index.module.scss";

type Props = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
};

export default function Menu({ trigger, children, disabled }: Props) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        {trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles.content}
          sideOffset={12}
          align="start"
        >
          <DropdownMenu.Arrow className={styles.arrow} />
          <Flex.Col className={styles.items}>{children}</Flex.Col>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
