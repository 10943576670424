import cx from "clsx";
import DropdownMenu from "kreate-common/modules/kreate-ui/components/DropdownMenu";
import { httpPost$Logout } from "kreate-server/commands/Logout/fetcher";
import { useRouter } from "next/router";
import React from "react";
import {
  MdLogout,
  MdOutlineArrowDropDown,
  MdPerson,
  MdPhotoAlbum,
} from "react-icons/md";

import ButtonOnNavbar from "../ButtonOnNavbar";

import styles from "./index.module.scss";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";
import { shamelesslyRevalidateEverything } from "@/modules/common-utils/shamelesslyRevalidateEverything";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import { getLinkToPageGalleryCollector } from "@/modules/navigation/utils/getLinkTo";
import { useUser } from "@/modules/resources/hooks/useUser";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function ButtonUser({ className, style }: Props) {
  const router = useRouter();
  const loginStatus = useLoginStatus();

  const [user] = useUser(
    loginStatus?.loggedIn ? loginStatus.userId : undefined
  );

  const firstAddress = user?.walletAddresses?.[0];

  const logout = async () => {
    await httpPost$Logout("/api/v1/auth/logout");
    await shamelesslyRevalidateEverything();
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      {!loginStatus || !loginStatus.loggedIn ? (
        <ButtonOnNavbar
          content="Login"
          disabled={!loginStatus || !loginStatus.confirmed}
          onClick={() =>
            router.push(
              `/auth/login?${new URLSearchParams({
                redirectUrl: router.asPath,
              })}`
            )
          }
        />
      ) : (
        <DropdownMenu.Menu
          trigger={
            <ButtonOnNavbar
              iconRight={
                <MdOutlineArrowDropDown
                  size="24px"
                  style={{ margin: "0 -6px" }}
                />
              }
              content={<AvatarViewer value={loginStatus?.avatar} />}
            />
          }
        >
          <DropdownMenu.Item
            icon={<MdPerson />}
            content="Your Profile"
            onSelect={() =>
              loginStatus && router.push(`/user/${loginStatus.userId}`)
            }
          />
          {user && firstAddress ? (
            <DropdownMenu.Item
              icon={<MdPhotoAlbum />}
              content="Your Gallery"
              onSelect={() =>
                router.push(getLinkToPageGalleryCollector(firstAddress))
              }
            />
          ) : undefined}
          <DropdownMenu.Item
            icon={<MdLogout />}
            content="Log Out"
            onSelect={logout}
          />
        </DropdownMenu.Menu>
      )}
    </div>
  );
}
