import {
  Theme,
  isTheme,
} from "kreate-common/modules/kreate-ui/components/ThemeProvider/config";
import {
  getResourceKey$GetSessionPreferences,
  getSessionPreferences,
} from "kreate-server/commands/GetSessionPreferences/fetcher";
import { updateSessionPreferences } from "kreate-server/commands/UpdateSessionPreferences/fetcher";
import useSWR from "swr";

export function useThemeFromSessionPreferences() {
  const sessionPreferencesSWR = useSWR(
    getResourceKey$GetSessionPreferences(),
    () => getSessionPreferences()
  );

  const rawTheme = sessionPreferencesSWR.data?.theme;
  const theme: Theme = isTheme(rawTheme) ? rawTheme : "classicLight";

  const setTheme = async (theme: Theme) => {
    await updateSessionPreferences({ ...sessionPreferencesSWR.data, theme });
    await sessionPreferencesSWR.mutate();
  };

  return { theme, setTheme };
}
