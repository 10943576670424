import * as helios from "@hyperionbt/helios";
import * as Sentry from "@sentry/nextjs";
import cx from "clsx";
import DropdownMenu from "kreate-common/modules/kreate-ui/components/DropdownMenu";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import { useModalPromises } from "kreate-common/modules/kreate-ui/components/Modal/hoc/withModalPromises";
import React from "react";
import {
  MdCancel,
  MdContentCopy,
  MdLogout,
  MdOutlineArrowDropDown,
  MdSync,
} from "react-icons/md";
import { mutate } from "swr";

// TODO: @thanh-kreate: @kien-kreate please fix imports
import {
  authenticateByCardanoWallet,
  getUserFromCardanoWallet,
} from "../../../../../../containers/PageLogin/utils";
import ButtonOnNavbar from "../ButtonOnNavbar";

import styles from "./index.module.scss";

import { useCardanoWalletApi } from "@/modules/cardano-wallet";
import { WalletName } from "@/modules/cardano-wallet/types";
import { LogoWallet } from "@/modules/cardano-wallet-list/components/LogoWallet";
import {
  ALL_KNOWN_WALLETS,
  WalletInfo,
} from "@/modules/cardano-wallet-list/utils";
import useIsUserAgentLikelyMobile from "@/modules/common-hooks/useIsUserAgentLikelyMobile";
import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";
import { shamelesslyRevalidateEverything } from "@/modules/common-utils/shamelesslyRevalidateEverything";
import { AssetViewer$Ada$Compact } from "@/modules/kreate-components/components/AssetViewer$Ada$Compact";
import { useToast } from "@/modules/kreate-contexts/ToastContext";
import { useModalTrigger$ModalAlert } from "@/modules/kreate-modals/modals/ModalAlert/hooks";
import { useModalTrigger$ModalConfirm } from "@/modules/kreate-modals/modals/ModalConfirm/hooks";
import ModalConnectWallet from "@/modules/kreate-modals/modals/ModalConnectWallet";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

const IconDropdown = () => (
  <MdOutlineArrowDropDown style={{ margin: "0 -6px" }} />
);

export default function ButtonWallet({ className, style }: Props) {
  const api = useCardanoWalletApi();
  const modalTrigger$ModalConfirm = useModalTrigger$ModalConfirm();
  const modalTrigger$ModalAlert = useModalTrigger$ModalAlert();
  const { showModal } = useModalPromises();
  const { showError } = useToast();
  const loginStatus = useLoginStatus();

  const requestWalletInstallation = async (walletInfo: WalletInfo) => {
    // 1. If user does not use Chrome, an alert is displayed.
    if (!("chrome" in window)) {
      await modalTrigger$ModalAlert.openModal(
        `The selected wallet (${walletInfo.title}) has not been installed.`
      );
      return;
    }
    // 2. Otherwise, an additional option to navigate to installation page
    // is shown.
    const confirmedToInstall = await modalTrigger$ModalConfirm.openModal(
      `The selected wallet (${walletInfo.title}) has not been installed. Do you want to visit Chrome Web Store and install it now?`
    );
    if (!confirmedToInstall) return;
    // 3. If that option is selected, open a new tab navigating to the
    // installaton page
    window.open(walletInfo.url, "_blank", "noreferrer");
    const confirmedToReload = await modalTrigger$ModalConfirm.openModal(
      "After the wallet is installed, please reload to continue."
    );
    if (confirmedToReload) {
      location.reload();
    }
  };

  const isUserAgentLikelyMobile = useIsUserAgentLikelyMobile();

  const walletList: WalletName[] = isUserAgentLikelyMobile
    ? ["vespr", "flint"]
    : ["nami", "eternl", "lace", "flint", "typhoncip30", "gerowallet"];

  switch (api.type) {
    case "disconnected":
      return (
        <div className={cx(styles.container, className)} style={style}>
          <DropdownMenu.Menu
            trigger={
              <ButtonOnNavbar
                iconRight={<IconDropdown />}
                content="Connect Wallet"
              />
            }
          >
            {walletList
              .map((walletName) =>
                ALL_KNOWN_WALLETS.find((item) => item.name === walletName)
              )
              .map((item) =>
                !item ? undefined : (
                  <DropdownMenu.Item
                    key={item.name}
                    icon={<LogoWallet walletName={item.name} size="24px" />}
                    content={item.title}
                    onSelect={async () => {
                      try {
                        if (!api.isWalletInstalled(item.name)) {
                          await requestWalletInstallation(item);
                        } else {
                          const cip30Handle = await api.connect(item.name);
                          if (loginStatus?.loggedIn) {
                            return;
                          }
                          const cip30Wallet = new helios.Cip30Wallet(
                            cip30Handle
                          );
                          const user = await getUserFromCardanoWallet(
                            cip30Wallet
                          );
                          if (!user) {
                            const confirmed =
                              await modalTrigger$ModalConfirm.openModal(
                                "Do you want to create a new Profile?"
                              );
                            if (!confirmed) {
                              return;
                            }
                          }
                          const { userId } = await authenticateByCardanoWallet(
                            cip30Wallet
                          );
                          console.log(`logged in as ${userId}`);
                          await shamelesslyRevalidateEverything();
                        }
                      } catch (error) {
                        showError({ error });
                      }
                    }}
                  />
                )
              )}
            <DropdownMenu.Item
              content="View All"
              onSelect={async () => {
                await showModal<void>(({ resolve }) => (
                  <ModalConnectWallet
                    open
                    initialShowAllWallets
                    onSuccess={() => resolve()}
                    onCancel={resolve}
                  />
                ));
              }}
            />
          </DropdownMenu.Menu>
        </div>
      );

    case "connecting":
      return (
        <div className={cx(styles.container, className)} style={style}>
          <DropdownMenu.Menu
            trigger={
              <ButtonOnNavbar
                icon={
                  <LogoWallet
                    walletName={api.walletName}
                    size="24px"
                    style={{ filter: "grayscale(100%)" }}
                  />
                }
                content={`Connecting...`}
              />
            }
          >
            <DropdownMenu.Item
              icon={<MdCancel />}
              content="Abort"
              onSelect={() => api.abort()}
            />
          </DropdownMenu.Menu>
        </div>
      );

    case "loading":
      return (
        <div className={cx(styles.container, className)} style={style}>
          <DropdownMenu.Menu
            trigger={
              <ButtonOnNavbar
                icon={
                  <LogoWallet
                    walletName={api.walletName}
                    size="24px"
                    style={{ filter: "grayscale(100%)" }}
                  />
                }
                iconRight={<IconDropdown />}
              >
                <AssetViewer$Ada$Compact
                  as="span"
                  lovelaceAmount={api.lovelaceAmount}
                />
              </ButtonOnNavbar>
            }
          >
            <DropdownMenu.Item
              icon={<MdLogout />}
              content="Disconnect"
              onSelect={() => api.disconnect()}
            />
          </DropdownMenu.Menu>
        </div>
      );

    case "ready":
      return (
        <div className={cx(styles.container, className)} style={style}>
          <DropdownMenu.Menu
            trigger={
              <ButtonOnNavbar
                icon={<LogoWallet walletName={api.walletName} size="24px" />}
                iconRight={<IconDropdown />}
              >
                <AssetViewer$Ada$Compact
                  as="span"
                  lovelaceAmount={api.lovelaceAmount}
                />
              </ButtonOnNavbar>
            }
          >
            <DropdownMenu.Item
              onSelect={async () => {
                await navigator.clipboard.writeText(api.address);
                alert("Copied to clipboard");
              }}
            >
              <Flex.Row alignItems="center" gap="12px">
                <div className={styles.addressViewer}>
                  {"Wallet: "}
                  {api.address}
                </div>
                <MdContentCopy size="24px" />
              </Flex.Row>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              icon={<MdSync />}
              content="Refresh Wallet"
              onSelect={async () => {
                // NOTE: a quick-and-dirty way to revalidate data from:
                // apps/kreate-web/modules/cardano-wallet/hooks/useBasicWalletInfo.ts
                void mutate(
                  (key) =>
                    Array.isArray(key) &&
                    [
                      "582f08df-c3b3-4922-aeb2-805a8725acff",
                      "e8957fa6-da33-4fd2-b041-c08bfc95cf02",
                    ].includes(key[0]),
                  null
                );

                const [
                  networkId,
                  usedAddresses,
                  unusedAddresses,
                  utxos,
                  collateral,
                ] = await Promise.allSettled([
                  api.cip30Handle?.getNetworkId?.(),
                  api.cip30Handle?.getUsedAddresses?.(),
                  api.cip30Handle?.getUnusedAddresses?.(),
                  api.cip30Handle?.getUtxos?.(),
                  api.cip30Handle?.getCollateral?.(),
                ]);

                console.log({
                  networkId,
                  usedAddresses,
                  unusedAddresses,
                  utxos,
                  collateral,
                });

                Sentry.captureEvent({
                  message: "Clicked button Refresh Wallet",
                  level: "info",
                  extra: {
                    walletName: api.walletName,
                    address: api.address,
                    lovelaceAmount: api.lovelaceAmount,
                    networkId,
                    usedAddresses,
                    unusedAddresses,
                    utxos,
                    collateral,
                  },
                });
              }}
            />
            <DropdownMenu.Item
              icon={<MdLogout />}
              content="Disconnect"
              onSelect={() => api.disconnect()}
            />
          </DropdownMenu.Menu>
        </div>
      );
  }
}
