import cx from "clsx";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";

import ButtonUser from "../ButtonUser";
import ButtonWallet from "../ButtonWallet";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function ButtonUserFusedWithButtonWallet({
  className,
  style,
}: Props) {
  return (
    <Flex.Row className={cx(styles.container, className)} style={style}>
      <ButtonUser className={styles.fusedButtonLeft} />
      <div className={styles.divider}>
        <div className={styles.dividerContent} />
      </div>
      <ButtonWallet className={styles.fusedButtonRight} />
    </Flex.Row>
  );
}
