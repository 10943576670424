import cx from "clsx";
import { ThemeContext } from "kreate-common/modules/kreate-ui/components/ThemeProvider/config";
import React from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";

import ButtonOnNavbar from "../ButtonOnNavbar";

import styles from "./index.module.scss";
import { isDark, nextTheme } from "./utils";

import { useThemeFromSessionPreferences } from "@/modules/kreate-components/hooks/useThemeFromSessionPreferences";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function ButtonSwitchTheme({ className, style }: Props) {
  const theme = React.useContext(ThemeContext);
  const { setTheme } = useThemeFromSessionPreferences();

  return (
    <div className={cx(styles.container, className)} style={style}>
      <ButtonOnNavbar
        size="md-square"
        icon={isDark(theme) ? <MdDarkMode /> : <MdLightMode />}
        disabled={theme === "default"}
        onClick={async () => {
          await setTheme(nextTheme(theme));
        }}
        title="Switch theme"
      />
    </div>
  );
}
