import { setCookie } from "cookies-next";

import { SessionPreferences } from "../GetSessionPreferences/typing";

export async function updateSessionPreferences(
  sessionPreferences: SessionPreferences
) {
  const text = JSON.stringify(sessionPreferences);
  setCookie("session-preferences", text);
}
