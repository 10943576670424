import * as RxDropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "clsx";
import ButtonTransparentV2 from "kreate-common/modules/kreate-ui/components/ButtonTransparentV2";
import { useRouter } from "next/router";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";

import { Product } from "../../../NavbarV4/types";

import styles from "./index.module.scss";

import { useBusyCount } from "@/modules/common-hooks/useBusyCount";

const PRODUCT_LABEL: Record<Product, string> = {
  art: "Art",
  market: "Marketplace",
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  product: Product;
};

export default function ProductSwitcher({ className, style, product }: Props) {
  // const loginStatus = useLoginStatus();
  const router = useRouter();

  const { busy, withDefaultErrorHandler } = useBusyCount();

  return (
    <div className={cx(styles.container, className)} style={style}>
      <RxDropdownMenu.Root>
        <RxDropdownMenu.Trigger asChild>
          <ButtonTransparentV2
            aria-label="Select products"
            content={
              <span className={styles.label} style={{ textTransform: "none" }}>
                {PRODUCT_LABEL[product]}
              </span>
            }
            iconRight={
              <MdArrowDropDown size="24px" style={{ margin: "-7px" }} />
            }
            loading={busy}
            disabled={busy}
          />
        </RxDropdownMenu.Trigger>
        <RxDropdownMenu.Portal>
          <RxDropdownMenu.Content className={styles.content} sideOffset={4}>
            <RxDropdownMenu.Item
              className={styles.item}
              onClick={withDefaultErrorHandler(async () => {
                await router.push("/");
              })}
            >
              {"Art"}
            </RxDropdownMenu.Item>
            <RxDropdownMenu.Item
              className={styles.item}
              onClick={withDefaultErrorHandler(async () => {
                await router.push("/market");
              })}
            >
              {"Marketplace"}
            </RxDropdownMenu.Item>
            <RxDropdownMenu.Arrow className={styles.arrow} />
          </RxDropdownMenu.Content>
        </RxDropdownMenu.Portal>
      </RxDropdownMenu.Root>
    </div>
  );
}
