import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "clsx";
import * as React from "react";

import styles from "./index.module.scss";

type Props = {
  icon?: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
  onSelect?: () => void;
  disabled?: boolean;
  className?: string;
};

export default function Item({
  icon,
  content,
  children = content,
  onSelect,
  disabled,
  className,
}: Props) {
  return (
    <DropdownMenu.Item
      className={cx(styles.container, className)}
      onSelect={onSelect}
      disabled={disabled}
    >
      <div className={styles.content}>
        {icon ? <div className={styles.icon}>{icon}</div> : null}
        {children ? <div className={styles.children}>{children}</div> : null}
      </div>
    </DropdownMenu.Item>
  );
}
