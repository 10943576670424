import { getCookie } from "cookies-next";

import { ResourceKey } from "../../types";

import { SessionPreferences } from "./typing";

export async function getSessionPreferences() {
  // on client-side, the following call will get data from document.cookie
  const text = getCookie("session-preferences");
  if (typeof text !== "string") return undefined;
  try {
    const json = JSON.parse(text);
    const data = SessionPreferences.parse(json);
    return data;
  } catch {
    return undefined;
  }
}

export function getResourceKey$GetSessionPreferences(): ResourceKey {
  return ["f6cb4c6f-419a-4fea-83ab-6de1fb6c02bb"];
}
