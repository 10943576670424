import cx from "clsx";
import DropdownMenu from "kreate-common/modules/kreate-ui/components/DropdownMenu";
import { useRouter } from "next/router";
import React from "react";
import {
  MdAdd,
  MdAddPhotoAlternate,
  MdPhotoSizeSelectActual,
} from "react-icons/md";

import ButtonOnNavbar from "../ButtonOnNavbar";

import styles from "./index.module.scss";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function ButtonAdd({ className, style }: Props) {
  const loginStatus = useLoginStatus();
  const router = useRouter();

  const loginAndOpen = async (url: string) => {
    await router.push(
      loginStatus?.loggedIn
        ? url
        : `/auth/login?${new URLSearchParams({ redirectUrl: url })}`
    );
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      <DropdownMenu.Menu
        trigger={
          <ButtonOnNavbar
            size="md-square"
            icon={<MdAdd />}
            disabled={!loginStatus?.confirmed}
            title="Add"
          />
        }
      >
        <DropdownMenu.Item
          icon={<MdAddPhotoAlternate />}
          content="Artwork"
          onSelect={() => loginAndOpen("/new/artwork")}
        />
        <DropdownMenu.Item
          icon={<MdPhotoSizeSelectActual />}
          content="Collection"
          onSelect={() => loginAndOpen("/new/collection")}
        />
      </DropdownMenu.Menu>
    </div>
  );
}
