import cx from "clsx";
import { FileHandle } from "kreate-common/modules/business-types";
import Image from "next/image";
import React from "react";

import svgDefaultAvatar from "./assets/default-avatar.svg";
import styles from "./index.module.scss";

import { ImageViewer } from "@/modules/kreate-components/components/ImageViewer";

type Length = `${number}${"px" | "em"}`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  value: FileHandle | null | undefined;
  size?: Length;
};

export default function AvatarViewer({
  className,
  style,
  value,
  size = "28px",
}: Props) {
  return (
    <div
      className={cx(styles.container, className)}
      style={{ ...style, width: size, height: size }}
    >
      {value ? (
        <ImageViewer value={value} sizing="fill" sizes={size} />
      ) : (
        <Image src={svgDefaultAvatar} alt="" fill sizes={size} />
      )}
    </div>
  );
}
