import { Theme } from "kreate-common/modules/kreate-ui/components/ThemeProvider/config";

export function isDark(theme: Theme) {
  return theme === "classicDark";
}

export function nextTheme(theme: Theme): Theme {
  switch (theme) {
    case "classicLight":
      return "classicDark";
    case "classicDark":
      return "chocolate";
    case "chocolate":
      return "classicLight";
    default:
      return "classicDark";
  }
}
