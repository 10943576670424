import { CLIENT_ENV } from "kreate-env/client";
import { ImageLoaderProps } from "next/image";

// The config below must be coupled to:
// apps/kreate-web/next.config.js
const SUPPORTED_WIDTHS = [
  16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 768, 1024, 1536, 2048, 3072,
  4096,
];

function getSupportedWidth(width: number) {
  return (
    SUPPORTED_WIDTHS.find((value) => value >= width) ||
    SUPPORTED_WIDTHS[SUPPORTED_WIDTHS.length - 1]
  );
}

export function kreateImageLoader({ src, width }: ImageLoaderProps) {
  const supportedWidth = getSupportedWidth(width);
  return `https://${CLIENT_ENV.CLOUDFRONT_HOST}/image-optimize/tr:w-${supportedWidth}/blobs/${src}`;
}

export function kreateImageLoader$PreventAnimation({
  src,
  width,
}: ImageLoaderProps) {
  const supportedWidth = getSupportedWidth(width);
  return `https://${CLIENT_ENV.CLOUDFRONT_HOST}/image-optimize/tr:w-${supportedWidth},thumbnail/blobs/${src}`;
}

export function getThumbnailUrlFromBlobId(blobId: string) {
  return `https://${CLIENT_ENV.CLOUDFRONT_HOST}/image-optimize/tr:w-1024,s-5000000,thumbnail/blobs/${blobId}`;
}
