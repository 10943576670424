import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import ModalV2 from "kreate-common/modules/kreate-ui/components/ModalV2";
import React from "react";

import styles from "./index.module.scss";

type Props = {
  open: boolean;
  onClose?: () => void;
  message: React.ReactNode;
  modalProps?: Partial<React.ComponentProps<typeof ModalV2>>;
};

export default function ModalAlert({
  open,
  onClose,
  message,
  modalProps,
}: Props) {
  return (
    <ModalV2
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="sm"
      {...modalProps}
    >
      <ModalV2.Content>
        <div className={styles.message}>{message}</div>
      </ModalV2.Content>
      <ModalV2.Actions>
        <ButtonPrimary size="md" content="OK" onClick={onClose} />
      </ModalV2.Actions>
    </ModalV2>
  );
}
