import CustomButton from "kreate-common/modules/kreate-ui/components/CustomButton";

import styles from "./index.module.scss";

const ButtonOnNavbar = CustomButton.withDefaultProps({
  color: [styles.buttonColor],
  size: "md",
  border: "solid",
});

export default ButtonOnNavbar;
