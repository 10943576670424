import {
  Artwork,
  BaseGalleryArtwork,
  CuratedCollection,
  GalleryAlbum,
  GalleryArtwork,
  PolicyDetailInfo,
  SimpleCollection,
  SimpleGalleryAlbum,
  SimplePolicyDetailInfo,
  SimpleUser,
} from "kreate-common/modules/business-types";
import {
  Collection,
  CollectionUrlHandleString,
  ObjectIdAsHexString,
} from "kreate-common/modules/business-types";

import { urlQueryCodecs$PageGalleryArtwork } from "../../../containers/PageGalleryArtwork/config";

type AddressIsh = string; // addr1ess or $ada-handle
type AlbumIsh = string; // album id or album handle

function getUrl(path: string, query: Record<string, string | undefined>) {
  const search = new URLSearchParams();
  Object.entries(query).forEach(([k, v]) => v != null && search.append(k, v));
  const queryString = search.toString();
  return queryString ? path + "?" + queryString : path;
}

export function getLinkToPageCollection(
  target: SimpleCollection | ObjectIdAsHexString | CollectionUrlHandleString
): string {
  switch (typeof target) {
    case "string":
      return `/collection/${target}`;

    case "object":
      return `/collection/${target.handle || target.collectionId}`;
  }
}

export function getLinkToPageUpdateCollection(
  target: Collection | ObjectIdAsHexString | CollectionUrlHandleString
): string {
  switch (typeof target) {
    case "string":
      return `/collection/${target}/edit`;

    case "object":
      return `/collection/${target.handle || target.collectionId}/edit`;
  }
}

export function getLinkToPageProfile(
  target: SimpleUser | ObjectIdAsHexString
): string {
  switch (typeof target) {
    case "string":
      return `/user/${target}`;
    case "object":
      return target.handle ? `/@${target.handle}` : `/user/${target.userId}`;
  }
}

export function getLinkToPageArtwork(
  target: Pick<Artwork, "artworkId"> | ObjectIdAsHexString
): string {
  switch (typeof target) {
    case "string":
      return `/artwork/${target}`;
    case "object":
      return `/artwork/${target.artworkId}`;
  }
}

export function getLinkToPageGalleryArtwork(
  target: GalleryArtwork | BaseGalleryArtwork | ObjectIdAsHexString,
  defaultTab: "info" | "buy" | "sell"
): string {
  const codecs = urlQueryCodecs$PageGalleryArtwork;
  const search = new URLSearchParams();
  if (codecs.activePage.fromValue(defaultTab)) {
    search.append(
      codecs.activePage.name,
      codecs.activePage.fromValue(defaultTab)
    );
  }

  const assetIsh =
    typeof target === "string"
      ? target
      : target.assetFingerprint || target.assetId;

  const path = assetIsh.startsWith("asset1")
    ? `/${assetIsh}`
    : `/gallery-artwork/${assetIsh}`;

  return path + (search.toString() ? "?" + search.toString() : "");
}

export function getLinkToPageGalleryCollector(target: AddressIsh) {
  return target.startsWith("addr1") ||
    target.startsWith("addr_test1") ||
    target.startsWith("stake1") ||
    target.startsWith("stake_test1") ||
    target.startsWith("$")
    ? `/${target}`
    : `/addressIsh/${target}`;
}

export function getLinkToPageGalleryCollector$Edit(addressIsh: AddressIsh) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-edit`;
  } else {
    return `/addressIsh/${addressIsh}/-edit`;
  }
}

export function getLinkToPageGalleryCollector$HiddenAssets(
  addressIsh: AddressIsh
) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-hidden`;
  } else {
    return `/addressIsh/${addressIsh}/-hidden`;
  }
}

export function getLinkToPageGalleryCollector$AllAssets(
  addressIsh: AddressIsh
) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-all-assets`;
  } else {
    return `/addressIsh/${addressIsh}/-all-assets`;
  }
}

export function getLinkToPageGalleryCollector$ForSale(addressIsh: AddressIsh) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-for-sale`;
  } else {
    return `/addressIsh/${addressIsh}/-for-sale`;
  }
}

export function getLinkToPageGalleryCollector$SentOffers(
  addressIsh: AddressIsh
) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-sent-offers`;
  } else {
    return `/addressIsh/${addressIsh}/-sent-offers`;
  }
}

export function getLinkToPageGalleryCollector$ReceivedOffers(
  addressIsh: AddressIsh
) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-received-offers`;
  } else {
    return `/addressIsh/${addressIsh}/-received-offers`;
  }
}

export function getLinkToPageGalleryCollector$UnsortedAssets(
  addressIsh: AddressIsh
) {
  if (
    addressIsh.startsWith("addr1") ||
    addressIsh.startsWith("addr_test1") ||
    addressIsh.startsWith("stake1") ||
    addressIsh.startsWith("stake_test1") ||
    addressIsh.startsWith("$")
  ) {
    return `/${addressIsh}/-unsorted`;
  } else {
    return `/addressIsh/${addressIsh}/-unsorted`;
  }
}

export function getLinkToPageGalleryCollector$Album(
  addressIsh: AddressIsh,
  target: AlbumIsh | SimpleGalleryAlbum
) {
  switch (typeof target) {
    case "string":
      return `/${addressIsh}/${target}`;
    case "object":
      return `/${addressIsh}/${target.handle || target.galleryAlbumId}`;
    default:
      return "#disabled";
  }
}

export function getLinkToPageRoyalties({
  collectionId,
}: {
  collectionId: string | null | undefined;
}) {
  const search = new URLSearchParams();
  if (collectionId === null) {
    search.append("collectionId", "-");
  } else if (collectionId !== undefined) {
    search.append("collectionId", collectionId);
  }
  return "/me/royalties" + (search.toString() ? "?" + search.toString() : "");
}

export function getLinkToPageCuratedCollection(
  target: ObjectIdAsHexString | CuratedCollection
): string {
  switch (typeof target) {
    case "string":
      return `/curated-collection/${target}`;

    case "object":
      return `/curated-collection/${target.handle || target.collectionId}`;
  }
}

export function getLinkToPageUpdateCuratedCollection(
  target: ObjectIdAsHexString | CuratedCollection
): string {
  switch (typeof target) {
    case "string":
      return `/curated-collection/${target}/edit`;

    case "object":
      return `/curated-collection/${target.collectionId}/edit`;
  }
}

export function getLinkToPageExploreAssets(searchQuery: string) {
  const search = new URLSearchParams();
  searchQuery && search.append("q", searchQuery);
  return "/explore/assets" + (search.toString() ? "?" + search.toString() : "");
}

export function getLinkToPageExplorePolicies(searchQuery: string) {
  const search = new URLSearchParams();
  searchQuery && search.append("q", searchQuery);
  return (
    "/explore/policies" + (search.toString() ? "?" + search.toString() : "")
  );
}

export function getLinkToPagePolicy(
  target: SimplePolicyDetailInfo | PolicyDetailInfo | string
) {
  switch (typeof target) {
    case "string":
      return `/policy/${target}`;

    case "object":
      return `/policy/${target.policyId}`;
  }
}

export function getLinkToPageCreateArtwork() {
  return "/new/artwork";
}

export function getLinkToPageCreateCollection() {
  return "/new/collection";
}

export function getLinkToPageLogin({
  redirectUrl,
}: { redirectUrl?: string } = {}) {
  return getUrl("/auth/login", { redirectUrl });
}

export function getLinkToPageExploreArtworks() {
  return "/explore/artworks";
}

export function getLinkToPageExploreCollections() {
  return "/explore/collections";
}

export function getLinkToPageExploreArtists() {
  return "/explore/artists";
}
