import cx from "clsx";
import { clamp } from "common-utils/utils/arithmetic";
import { FileHandle } from "kreate-common/modules/business-types";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Image from "next/image";
import * as React from "react";
import { MdOutlineBrokenImage } from "react-icons/md";

import styles from "./index.module.scss";
import { kreateImageLoader, kreateImageLoader$PreventAnimation } from "./utils";

const MAX_ASPECT_RATIO = 4;
const MIN_ASPECT_RATIO = 1 / MAX_ASPECT_RATIO;

const SIZING = {
  fill: styles.sizingFill,
  "auto-height": styles.sizingAutoHeight,
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  value: FileHandle | null | undefined;
  sizing: keyof typeof SIZING;
  objectFit?: "cover" | "contain";
  /**
   * https://nextjs.org/docs/api-reference/next/image#sizes
   * https://web.dev/learn/design/responsive-images/#sizes
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#sizes
   */
  sizes: string | undefined;
  icons?: React.ReactNode[];
  preventAnimation?: boolean;
};

type ForwardedProps = React.ComponentProps<typeof Image>;

type Props = OwnProps & Omit<ForwardedProps, keyof OwnProps | "alt" | "src">;

type State = {
  hasError: boolean;
};

export class ImageViewer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_error: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, info: unknown) {
    console.error(error);
    console.info(info);
  }

  render() {
    const {
      className,
      style,
      value,
      objectFit = "cover",
      sizes,
      sizing,
      icons,
      preventAnimation,
    } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div
          className={cx(styles.container, className, styles.hasError)}
          style={style}
        >
          <MdOutlineBrokenImage />
        </div>
      );
    }

    if (!value) {
      return (
        <div
          className={cx(styles.container, className, styles.noImage)}
          style={style}
        ></div>
      );
    }

    const aspectRatio =
      value.blob.width && value.blob.height
        ? clamp(
            MIN_ASPECT_RATIO,
            value.blob.width / value.blob.height,
            MAX_ASPECT_RATIO
          )
        : undefined;

    return (
      <div
        className={cx(styles.container, className, SIZING[sizing])}
        style={Object.assign({ ...style }, { "--aspect-ratio": aspectRatio })}
      >
        <Image
          style={{ objectFit }}
          src={value.blob.blobId}
          loader={
            preventAnimation
              ? kreateImageLoader$PreventAnimation
              : kreateImageLoader
          }
          alt=""
          sizes={sizes}
          fill
        />
        {icons ? (
          <Flex.Row
            className={styles.iconContainer}
            gap="12px"
            alignItems="center"
          >
            {icons}
          </Flex.Row>
        ) : null}
      </div>
    );
  }
}
