import { useModalPromises } from "kreate-common/modules/kreate-ui/components/Modal/hoc/withModalPromises";

import ModalAlert from ".";

export function useModalTrigger$ModalAlert() {
  const { showModal } = useModalPromises();

  const openModal = async (
    message: React.ReactNode,
    otherProps?: Partial<
      Omit<
        React.ComponentProps<typeof ModalAlert>,
        "onClose" | "message" | "open"
      >
    >
  ) =>
    await showModal<void>(({ resolve }) => (
      <ModalAlert
        open
        message={message}
        onClose={() => resolve()}
        {...otherProps}
      />
    ));

  return { openModal };
}
