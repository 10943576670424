import * as RxDropdownMenu from "@radix-ui/react-dropdown-menu";
import Link from "next/link";
import React from "react";

import styles from "./index.module.scss";

type SubItem =
  | {
      type: "action";
      label: React.ReactNode;
      onSelect?: () => void;
      disabled?: boolean;
    }
  | {
      type: "link";
      label: React.ReactNode;
      href?: string | undefined;
    }
  | { type: "separator" };

type Item =
  | SubItem
  | { type: "group"; label: React.ReactNode; items: SubItem[] }
  | { type: "separator" };

type Props = {
  trigger: React.ReactNode;
  items: Item[];
};

export default function DropdownMenuV2({ trigger, items }: Props) {
  const renderAction = (
    item: Extract<Item, { type: "action" }>,
    key: React.Key
  ) => (
    <RxDropdownMenu.Item
      key={key}
      className={styles.menuItem}
      onClick={item.onSelect}
      disabled={item.disabled}
    >
      {item.label}
    </RxDropdownMenu.Item>
  );

  const renderLink = (
    item: Extract<Item, { type: "link" }>,
    key: React.Key
  ) => (
    <RxDropdownMenu.Item key={key} className={styles.menuItem} asChild>
      <Link href={item.href || "#disabled"}>{item.label}</Link>
    </RxDropdownMenu.Item>
  );

  const renderSeparator = (key: React.Key) => (
    <RxDropdownMenu.Separator className={styles.menuSeparator} key={key} />
  );

  return (
    <RxDropdownMenu.Root>
      <RxDropdownMenu.Trigger asChild>{trigger}</RxDropdownMenu.Trigger>
      <RxDropdownMenu.Portal>
        <RxDropdownMenu.Content className={styles.menuContent}>
          {items.map((item, index) => {
            switch (item.type) {
              case "separator":
                return renderSeparator(index);
              case "action":
                return renderAction(item, index);
              case "link":
                return renderLink(item, index);
              case "group":
                return (
                  <RxDropdownMenu.Group
                    key={index}
                    className={styles.menuGroup}
                  >
                    <RxDropdownMenu.Label className={styles.menuLabel}>
                      {item.label}
                    </RxDropdownMenu.Label>
                    {item.items.map((subItem, subIndex) => {
                      switch (subItem.type) {
                        case "action":
                          return renderAction(subItem, subIndex);
                        case "link":
                          return renderLink(subItem, subIndex);
                      }
                    })}
                  </RxDropdownMenu.Group>
                );
            }
          })}
          <RxDropdownMenu.Arrow className={styles.menuArrow} />
        </RxDropdownMenu.Content>
      </RxDropdownMenu.Portal>
    </RxDropdownMenu.Root>
  );
}
