import cx from "clsx";
import { ThemeContext } from "kreate-common/modules/kreate-ui/components/ThemeProvider/config";
import React from "react";
import { MdMenu } from "react-icons/md";

import DropdownMenuV2 from "../DropdownMenuV2";

import styles from "./index.module.scss";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";
import ButtonOnNavbar from "@/modules/kreate-components/components/NavbarV2/components/ButtonOnNavbar";
import { nextTheme } from "@/modules/kreate-components/components/NavbarV2/components/ButtonSwitchTheme/utils";
import { useThemeFromSessionPreferences } from "@/modules/kreate-components/hooks/useThemeFromSessionPreferences";
import {
  getLinkToPageCreateArtwork,
  getLinkToPageCreateCollection,
  getLinkToPageExploreArtists,
  getLinkToPageExploreArtworks,
  getLinkToPageExploreAssets,
  getLinkToPageExploreCollections,
  getLinkToPageExplorePolicies,
  getLinkToPageLogin,
} from "@/modules/navigation/utils/getLinkTo";

type Feature =
  | "add-artwork"
  | "add-collection"
  | "explore-art"
  | "explore-marketplace"
  | "switch-theme"
  | "separator";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  features: Feature[];
};

function Root({ className, style, features }: Props) {
  const loginStatus = useLoginStatus();
  const theme = React.useContext(ThemeContext);
  const { setTheme } = useThemeFromSessionPreferences();

  return (
    <DropdownMenuV2
      trigger={
        <ButtonOnNavbar
          className={cx(styles.root, className)}
          style={style}
          size="md-square"
          padding="minimal"
          icon={<MdMenu />}
        />
      }
      items={features.map((feature) => {
        switch (feature) {
          case "separator":
            return { type: "separator" };
          case "add-artwork":
            return {
              type: "link",
              label: "Add Artwork",
              href: loginStatus?.loggedIn
                ? getLinkToPageCreateArtwork()
                : getLinkToPageLogin({
                    redirectUrl: getLinkToPageCreateArtwork(),
                  }),
            };
          case "add-collection":
            return {
              type: "link",
              label: "Add Collection",
              href: loginStatus?.loggedIn
                ? getLinkToPageCreateCollection()
                : getLinkToPageLogin({
                    redirectUrl: getLinkToPageCreateCollection(),
                  }),
            };
          case "explore-art":
            return {
              type: "group",
              label: "Explore",
              items: [
                {
                  type: "link",
                  label: "Artworks",
                  href: getLinkToPageExploreArtworks(),
                },
                {
                  type: "link",
                  label: "Collections",
                  href: getLinkToPageExploreCollections(),
                },
                {
                  type: "link",
                  label: "Artists",
                  href: getLinkToPageExploreArtists(),
                },
              ],
            };
          case "explore-marketplace":
            return {
              type: "group",
              label: "Explore",
              items: [
                {
                  type: "link",
                  label: "Assets",
                  href: getLinkToPageExploreAssets(""),
                },
                {
                  type: "link",
                  label: "Policies",
                  href: getLinkToPageExplorePolicies(""),
                },
              ],
            };
          case "switch-theme":
            return {
              type: "action",
              label: "Switch Theme",
              onSelect: () => void setTheme(nextTheme(theme)),
              disabled: theme === "default",
            };
        }
      })}
    />
  );
}

const ButtonHamburgerV2 = Root;

export default ButtonHamburgerV2;
