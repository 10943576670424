import {
  ObjectIdAsHexString,
  User,
} from "kreate-common/modules/business-types";
import {
  DisplayableError,
  useDisplayableError,
} from "kreate-common/modules/displayable-error";
import {
  getResourceKey$GetUser,
  httpGet$GetUser,
} from "kreate-server/commands/GetUser/fetcher";
import useSWR from "swr";

export function useUser(
  userId: ObjectIdAsHexString | null | undefined
): [User | undefined, DisplayableError | undefined] {
  const { data, error } = useSWR(
    getResourceKey$GetUser(userId ? { userId } : undefined),
    async () => {
      if (!userId) return undefined;
      return await httpGet$GetUser(`/api/v1/user/${userId}`);
    }
  );
  const displayableError = useDisplayableError(error, "Failed to fetch user");
  return [data, displayableError];
}
